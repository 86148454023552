.bgimage {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: linear-gradient(45deg, #47454500, #413f3f00),
    url(../../assets/images/img5.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bgimage .card {
  /* backdrop-filter: blur(10px); */
  background-color: #ffffff29;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.bgimage .card .logoimage {
  width: 100px;
}

.bgimage .card .loginheading {
  font-size: 25px;
  font-weight: 600;
  color: white;
}

.bgimage .card .subtext {
  font-size: 16px;
  font-weight: 600;
}

.bgimage .card .btnlogin {
  background-color: #247b0e;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
  box-shadow: none;
  border: none;
}

.bgimage .card .remembertext {
  font-size: 15px;
  font-weight: 500;
  color: white;
  text-align: center;
}

.bgimage .card .control-form {
  width: 100%;
  height: 40px;
  border: none;
  box-shadow: none;
  padding: 2px;
  font-size: 14px;
  color: black;
  background-color: white;
}

.bgimage .card .remembertext2 {
  font-size: 14px;
  font-weight: 500;
  color: white;
  text-align: center;
  margin-bottom: 3px;
}
