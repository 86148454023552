.adjustmentsection .card {
  border: 1px solid rgb(224, 229, 51);
  transition: all 0.3s ease-in-out;
}

.adjustmentsection .card.innercard {
  background-color:rgb(27 93 31)
}

.adjustmentsection .card {
  border: 2px solid #487006 !important;
  transition: all 0.3s ease-in-out;
}

.adjustmentsection .card:hover {
  box-shadow: inset 0px -1px 12px 3px #23a001 !important;
}

.jashja:hover {
  box-shadow: inset 0px -1px 12px 3px #23a001 !important;
}

.maincard:hover {
  box-shadow: inset 0px -1px 12px 3px #23a001 !important;
}

.myteamcard:hover {
  box-shadow: inset 0px -1px 12px 3px #23a001 !important;
}

.form-wrapper:hover {
  box-shadow:inset 0px -1px 12px 3px #23a001 !important;
}

.tree_icon:hover {
  box-shadow: inset 0px -1px 12px 3px #23a001 !important;
}

.card:hover {
  box-shadow: inset 0px -1px 12px 3px #23a001 !important
}
