.footerDasssh {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.page-footer {
  background: #1b5d1f;
  left: 0px;
  right: 0;
  text-align: center;
  padding: 7px;
  color: black !important;
  font-size: 14px;
  border-top:1px solid rgb(224 229 51);
  z-index: 3;
}

.page-footer p{
  color: white !important;
}