body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #354724;
  /* background: linear-gradient(45deg, #000000, #161616db),
    url(../src/assets/images/img6.jpg); */
    background:url(../src/assets/images/img6.jpg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loggHXN {
  width: 70px;
}

.bgHxnxnxn {
  background-color:rgb(27, 93, 31) !important;
  color: white !important;
}

.lenkxn {
  color: white;
  margin-right: 5px !important;
}
.lenkxn:hover {
  color: #000000 !important;
}
@media screen and (max-width: 550px) {
  .loggHXN {
    width: 50px;
  }

  .kapqpdknq {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  
}

@media (min-width: 551px) and (max-width: 950px) {
  .loggHXN {
    width: 70px;
  }
}

.lenk {
  margin-right: 23px;
}
